<template>
    <div id="mian">
        <div class="header">
            <span>新增角色</span>
        </div>
        <!-- 上面表单框 -->
        <div class="con_from">
            <p>
                <i>角色名称</i>
                <el-input v-model="input" style="width:200px;height:36px"></el-input>
            </p>
            <p>
                <i>角色概述</i>
                <el-input v-model="input" style="width:200px;height:36px"></el-input>
            </p>
        </div>
        
        
    </div>
</template>
<script>
import { system_roles, del_system_roles } from '../../../../utils/http';

export default {
    data(){
        return{
            value1:"",
            value2:"",
            roleslist:[]
        }
    },
    mounted(){
        this.getsystem_roles()
    },

    methods:{

        //删除角色
        getroles(id){
            console.log(id)
            this.$confirm('是否确认删除记录?','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(()=>{
                //console.log("row.id : ",row.id)
                 del_system_roles(id).then(res=>{
                    if(res.data.code === 200){
                        this.$message.success('删除成功');
                       this.getsystem_roles()
                    }else{
                        this.$message.error('删除失败');
                    }
                })
            })
        },
        
        //请求角色列表
        getsystem_roles(){
            system_roles().then(res=>{
                // console.log('111111111111',res)
                this.roleslist = res.data
                // console.log(this.roleslist)
            })
        },

        //新增按钮
        addclickHander(){
            this.$router.push("/user/businessList_shlb/addcommercial_jbxx")
        },
        

        
    }
    
}
</script>
<style scoped>
.lianjie{
    color: #008AFF;
    
}
.tab tr td p{
    width: 100%;
    text-align: center;
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(102,102,102,1);
}
.tab_title{
    background:rgba(244,245,246,1);
    border-top:1px solid rgba(220,224,230,1); 
}
.tab1 .tab tr{
    padding-left:20px; 
    height: 60px;
    border-bottom:1px solid rgba(220,224,230,1); 
}
.tab{
    border-collapse:collapse; 
    width: 100%;
    background: #fff;
    border-bottom:1px solid rgba(220,224,230,1); 
}
.tab1{
    margin-top:20px; 
}
.jiaoyi_box p b{
    font-size:24px;
    font-family:PingFang SC;
    font-weight:600;
    color:rgba(72,184,182,1);
}
.jiaoyi_box p i{
    font-size:12px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(153,153,153,1);
}
.jiaoyi_box p{
    padding-top:18px; 
    width: 100%;
    text-align: center;
}
.jiaoyi{
    width: 100%;
    height: 90px;
    margin-top:20px; 
}
.jiaoyi_box{
    float: left;
    width: 485px;
    height: 90px;
    background:rgba(255,255,255,1);
    box-shadow:0px 2px 9px 0px rgba(0, 0, 0, 0.05);
    border-radius:5px;
}
.jianju{
    
    padding-left:59px; 
}
.con_from p i{
    padding-right:12px; 
    font-size:14px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(0,0,0,1);
}
.con_from p{
    padding-top:20px; 
}
.header span{
    padding-right:35px; 
    font-size:18px;
    font-family:Microsoft YaHei;
    font-weight:400;
    color:rgba(51,51,51,1);
}
.header{
    width: 100%;
    line-height: 60px;
    height: 60px;
    border-bottom:1px solid  rgba(220,224,230,1);
}
#mian{
    padding-left:20px;
    padding-right:21px;  
    width: 96.5%;
    height: 100%;
}
</style>